<template>
  <section id="user-details">
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <dl class="row">
            <dt class="col-sm-3">
              Username
            </dt>
            <dd class="col-sm-9">
              {{ username }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Email
            </dt>
            <dd class="col-sm-9">
              {{ email }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Date joined
            </dt>
            <dd class="col-sm-9">
              {{ dateJoinedLocale }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Hubs
            </dt>
            <dd class="col-sm-9">
              <b-form-group>
                <v-select
                  multiple
                  v-model="selectedHubs"
                  label="name"
                  :options="hubs"
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="submitChanges"
              >
                Save changes
              </b-button>
            </dd>
          </dl>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormGroup,
} from "bootstrap-vue"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      username: "",
      email: "",
      dateJoined: "",
      hubs: [],
      selectedHubs: [],
    }
  },
  methods: {
    getUserDetails() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/users/" + this.$route.params.id + "/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          this.username = response.data.username
          this.email = response.data.email
          this.dateJoined = response.data.date_joined
          this.selectedHubs = response.data.hub_names
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getAllHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          this.hubs = response.data.results.map(item => {
            return { name: item.name, id: item.id }
          });
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    submitChanges() {
      var params = {}
      params["hubs"] = this.selectedHubs.map(item => { return item.id })

      axios
        .patch(process.env.VUE_APP_ROOT_API + "/users/" + this.$route.params.id + "/", params, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          console.log(response.status)
          this.$router.push({ name: "user-management" })
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    }
  },
  computed: {
    dateJoinedLocale() {
      return (new Date(this.dateJoined)).toLocaleString("de-DE", { timeZone: "Europe/Berlin" })
    },
  },
  created() {
    this.getUserDetails()
    this.getAllHubs()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
